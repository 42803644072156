import htmlSanitizer from "sanitize-html"
import { unescape } from "lodash"

export const defaultConfig = {
  textFilter: (text) => unescape(text),
  allowedTags: htmlSanitizer.defaults.allowedTags.concat(["img", "style"]),
  allowVulnerableTags: true,
  allowedAttributes: {
    ...htmlSanitizer.defaults.allowedAttributes,
    // iFrames are not allowed by default but when they are allowed, these are the attributes that are allowed
    iframe: [
      "src",
      "width",
      "height",
      "frameborder",
      "allow",
      "allowfullscreen"
    ],
    "*": ["style", "class", "title", "id"]
  }
}
/**
 * Sanitize a string to prevent XSS attacks
 * @param {string} html - The html string to sanitize
 * @param {config} config - The configuration object for the sanitizer
 * @returns {string} The sanitized string
 */
const sanitizeHTML = (html, config) => {
  const options = { ...defaultConfig, ...config }
  return htmlSanitizer(html, options)
}

export default sanitizeHTML
